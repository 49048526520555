<template>
  <div>
    <div
      v-if="company.status === 'INABILITADA' && !updatedCertificate"
      class="is-flex message-wrapper"
    >
      <div class="box is-inline-block custom-message-box">
        <div class="is-flex message-container">
          <b-icon class="custom-message-icon" icon="alert-box" type="is-warning"></b-icon>
          <p>É necessário enviar uma Certidão Simplificada da Junta Comercial atualizada.</p>
        </div>
      </div>
    </div>

    <div
      v-if="data.type === 'Certidão Completa da Junta Comercial'"
      class="is-flex message-wrapper"
    >
      <div class="box is-inline-block custom-message-box">
        <div class="is-flex message-container">
          <b-icon class="custom-message-icon" icon="alert-box" type="is-warning"></b-icon>
          <p>
            O sistema calcula a validade da Certidão Completa da Junta Comercial a partir da data de
            <strong>inserção</strong>. Caso sua empresa não atualize o documento em até 6 meses, uma
            notificação será enviada.
          </p>
        </div>
      </div>
    </div>

    <form>
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Documento"
          :type="campoInvalido('type', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('type', 'data') ? 'Documento é um campo obrigatório' : ''"
        >
          <b-select
            expanded
            placeholder="Documento"
            v-model="data.type"
            :disabled="disableFields"
            @click.native="$v.data.type.$touch()"
          >
            <option
              v-for="documentType in filteredDocumentTypes"
              :key="documentType.id"
              :value="documentType.type"
            >
              {{ documentType.type }}
            </option>
          </b-select>
        </b-field>

        <b-field
          expanded
          class="validity-field"
          label="Validade"
          v-if="displayValidityField"
          :type="campoInvalido('validity', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('validity', 'data') ? 'Validade é um campo obrigatório' : ''"
        >
          <b-datepicker
            editable
            placeholder="Validade"
            icon="calendar-today"
            v-mascara:data
            v-model="maskValues.validity"
            :disabled="disableFields"
            :years-range="yearsLimit"
            @input="date => updateDate(date)"
            @keypress.native="teclasPermitidasNumero($event)"
          >
            <button
              class="button is-primary br-4 mr-1"
              @click.prevent.stop="inserirHoje('validity', 'data', 'maskValues')"
            >
              <b-icon icon="calendar-today" />
              <span>Hoje</span>
            </button>

            <button
              class="button is-danger br-4"
              @click.prevent.stop="limparData('validity', 'data', 'maskValues')"
            >
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-field class="document-upload-field">
          <div class="field">
            <b-upload
              v-model="data.file"
              :disabled="disableFields || !data.type"
              @input="
                {
                  resetCompanyUpload(), saveDocument();
                }
              "
            >
              <a class="button is-primary is-fullwidth" :disabled="disableFields || !data.type">
                <b-icon icon="upload"></b-icon>
                <span>Carregar Arquivo</span>
              </a>
            </b-upload>
          </div>
        </b-field>
      </b-field>
    </form>

    <div>
      <b-progress
        show-value
        class="progress-upload"
        format="percent"
        type="is-info"
        size="is-medium"
        :value="companyUpload"
      ></b-progress>
    </div>

    <hr />

    <b-table
      paginated
      per-page="8"
      class="table is-fullwidth"
      v-if="companyDocuments.length"
      :data="companyDocuments"
    >
      <b-table-column sortable field="name" label="Documento" v-slot="props">
        <a
          download
          v-if="!disableFields || (lastValidation.status === 'PENDENTE' && editCompanyPermission)"
          :href="props.row.file | fileServer"
          >{{ props.row.type }}</a
        >
        <p v-else>{{ props.row.type }}</p>
      </b-table-column>

      <b-table-column
        centered
        sortable
        field="createdAt"
        label="Inserido em"
        width="140"
        v-slot="props"
      >
        <div class="inserted-column">
          {{ new Date(props.row.createdAt).toLocaleDateString('pt-BR') }}
        </div></b-table-column
      >

      <b-table-column
        centered
        sortable
        field="validity"
        label="Validade"
        width="140"
        v-slot="props"
        :visible="displayValidity"
      >
        <span
          v-if="!!props.row.validity"
          class="tag"
          :class="verifyValidity(props.row.validity) ? 'is-success' : 'is-danger'"
          >{{ new Date(props.row.validity).toLocaleDateString('pt-BR') }}</span
        >
      </b-table-column>

      <b-table-column centered :visible="!disableFields" v-slot="props">
        <b-tooltip label="Excluir">
          <button
            class="button is-danger"
            :disabled="disableFields || disableDelete"
            @click.prevent.stop="deleteDocument(props.row.id)"
          >
            <b-icon icon="delete" />
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';

import { fileServer } from '@/utils/functions';
import { uploadLimit } from '@/utils/data';

import campo from '@/mixins/formulario';

export default {
  name: 'SellerDocumentation',
  mixins: [campo],
  data() {
    const data = {
      type: '',
      validity: null,
      file: [],
    };

    const maskValues = {
      validity: null,
    };

    const loading = {
      general: false,
      save: false,
    };

    return {
      data,
      loading,
      maskValues,
      yearsLimit: [-119, 31],
      disableDelete: false,
    };
  },
  validations: {
    data: {
      type: { required },
      file: { required },
      validity: {
        required: requiredIf(function verificaObrigatorio() {
          if (this.data.type.includes('Certidão Negativa')) return true;
          if (this.data.type.includes('Registro')) return true;
          return false;
        }),
      },
    },
  },
  filters: {
    fileServer,
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company']),
    ...mapState('companiesDocuments', ['companyDocuments', 'companyUpload']),
    ...mapState('documentTypes', ['documentTypes']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      lastValidation: 'lastValidation',
      logged: 'getLogged',
      user: 'getUser',
    }),
    editCompanyPermission() {
      return this.user.empresas.some(
        companyUser => companyUser.id === this.company.id && companyUser.editar_empresa,
      );
    },
    disableFields() {
      return (
        !this.logged || this.lastValidation.status === 'PENDENTE' || !this.editCompanyPermission
      );
    },
    displayValidity() {
      return this.companyDocuments.some(document => document.validity);
    },
    displayValidityField() {
      return this.data.type.includes('Certidão Negativa') || this.data.type.includes('Registro');
    },
    filteredDocumentTypes() {
      return this.documentTypes
        .filter(documentType => documentType.profile)
        .reduce((documents, document) => {
          if (!document.unique) {
            documents.push(document);
          } else {
            const exists = this.companyDocuments.find(element => element.type === document.type);
            if (!exists) documents.push(document);
          }
          return documents;
        }, [])
        .sort((a, b) => a.type.localeCompare(b.type));
    },
    updatedCertificate() {
      const oneDay = 24 * 60 * 60 * 1000;
      const now = new Date();
      const invalidDate = new Date(now.getTime() - oneDay);

      return this.companyDocuments.some(
        document =>
          document.type === 'Certidão Simplificada da Junta Comercial' &&
          new Date(document.createdAt) >= invalidDate,
      );
    },
  },
  methods: {
    ...mapActions('documentTypes', ['fetchDocumentTypes']),
    ...mapActions('companiesDocuments', [
      'addCompanyDocument',
      'removeCompanyDocument',
      'resetCompanyUpload',
    ]),
    cleanFields() {
      this.data.type = '';
      this.data.validity = null;
      this.data.file = [];
      this.maskValues.validity = null;
      this.$v.data.$reset();
    },
    async deleteDocument(id) {
      this.disableDelete = true;
      try {
        await this.removeCompanyDocument({ companyId: this.company.id, documentId: id });
        this.$alerta('Documento excluído', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao excluir documento', 'is-danger');
      }
      this.disableDelete = false;
    },
    async saveDocument() {
      if (this.data.file.size > uploadLimit) {
        this.$alerta('Insira um arquivo com até 50MB', 'is-danger');
        return;
      }

      if (!this.validate()) {
        this.data.file = [];
        return;
      }

      const data = new FormData();

      let documentData;
      data.append('type', this.data.type);
      if (this.data.validity) {
        documentData = this.setDocumentDate();
        data.append('validity', documentData);
      }
      data.append('file', this.data.file);

      this.loading.save = true;
      try {
        await this.addCompanyDocument({ companyId: this.company.id, data });

        this.$alerta('Documento adicionado', 'is-success');
        this.cleanFields();
      } catch (error) {
        this.$alerta('Erro ao adicionar documento', 'is-danger');
      }
      this.loading.save = false;
    },
    updateDate(date) {
      this.$v.data.validity.$model = date;
      this.$v.data.validity.$touch();
    },
    validate() {
      if (this.$v.data.$invalid) {
        this.$v.data.$touch();
        this.$alerta('Preencha todo os dados');
        return false;
      }

      const { unique } = this.documentTypes.find(
        documentType => documentType.type === this.data.type,
      );
      const exists = this.companyDocuments.find(documento => documento.type === this.data.type);
      if (unique && exists) {
        return this.$alerta('Documento já adicionado', 'is-danger');
      }

      return true;
    },
    verifyValidity(validity) {
      const now = new Date();
      const documentDate = new Date(validity);
      return now <= documentDate;
    },
    setDocumentDate() {
      return new Date(
        new Date(
          this.data.validity.getFullYear(),
          this.data.validity.getMonth(),
          this.data.validity.getDate(),
        ).setHours(0, 0, 0, 0),
      );
    },
  },
  mounted() {
    this.resetCompanyUpload();
  },
  async created() {
    await this.fetchDocumentTypes();
  },
};
</script>

<style lang="scss" scoped>
.adicionar {
  height: 100%;
}

.custom-message-icon {
  margin-right: 0.5rem;
}

.inserted-column {
  min-width: 140px;
}

.message-container {
  align-items: center;
}

.message-wrapper {
  margin-bottom: 1.5rem;
}

.progress-upload {
  margin-top: 1.5rem;
}

.validity-field {
  min-width: 140px;
}

@media (min-width: 1024px) {
  .document-upload-field {
    margin-top: 31px;
  }
}

@media (max-width: 1023px) {
  .document-upload-field {
    margin-top: 0.75rem;

    .field .upload {
      width: 100%;
    }
  }

  .progress-upload {
    margin-top: 0.75rem;
  }
}
</style>
